import React from 'react';

import styles from './SpinnerLoader.module.css';

export interface ISpinnerLoader {
  extendClassName?: string;
}
/**
 * Based on https://tailwindcomponents.com/component/spinner
 */
const SpinnerLoader = ({ extendClassName = '' }: ISpinnerLoader) => {
  return (
    <div
      className={`${styles.SpinnerLoader} ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 ${extendClassName}`}
    ></div>
  );
};

export default SpinnerLoader;
