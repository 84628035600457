import { WEBSITE_NAME, WEBSITE_URL } from 'config/constants';
import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import React from 'react';

export interface IProductCheckoutSEO {
  icon?: string;
  ogIconUrl?: string;
  title?: string;
  description?: string;
  themeColor?: string;
}
const SEO = ({
  icon = '/branding/menobel-icon.png',
  ogIconUrl = 'https://menobel.com/branding/logo-orange-menobel-1200x600.png',
  title = 'Menobel',
  description = `A união entre quem sabe e quem quer saber!`,
  themeColor = '#fff3eb',
}: IProductCheckoutSEO) => {
  const router = useRouter();
  const currentUrl = `${WEBSITE_URL}${router.asPath}`;
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href={icon} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" key="twcard" />
      <meta name="twitter:creator" content={WEBSITE_NAME} key="twhandle" />

      {/* Open Graph */}
      <meta property="og:url" content={currentUrl} key="ogurl" />
      <meta property="og:image" content={ogIconUrl} key="ogimage" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />
      <meta property="og:site_name" content={WEBSITE_NAME} key="ogsitename" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta name="HandheldFriendly" content="true" />

      <link rel="shortcut icon" href="/branding/menobel-icon.png" />
      <link rel="apple-touch-icon" href="/branding/menobel-icon.png" />

      <meta name="theme_color" content={themeColor} />

      <meta name="application-name" content={title} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="description" content={description} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      {/* <meta
        name="msapplication-config"
        content="/static/icons/browserconfig.xml"
      /> */}
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content={themeColor} />

      <link rel="apple-touch-icon" href="/branding/menobel-icon.png" />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/branding/menobel-icon.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/branding/menobel-icon.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href="/branding/menobel-icon.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/branding/menobel-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/branding/menobel-icon.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="mask-icon"
        href="/branding/menobel-icon.png"
        color={themeColor}
      />
      <link rel="shortcut icon" href="/branding/menobel-icon.png" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
      />
    </Head>
  );
};

export default SEO;
