import React, { useEffect, useMemo } from 'react';
import {
  FiAlertCircle,
  FiXCircle,
  FiCheckCircle,
  FiInfo,
} from 'react-icons/fi';
import { ToastMessage, useToast } from 'contexts/Toast';
import { animated } from 'react-spring';

interface ToastProps {
  message: ToastMessage;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style: object;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  const toastColors = useMemo(() => {
    switch (message.type) {
      case 'success':
        return 'bg-green-50 text-green-700';
      case 'error':
        return 'bg-red-50 text-red-700';
      default:
      case 'info':
        return 'bg-blue-100 text-blue-700';
    }
  }, [message.type]);

  return (
    <animated.div
      key={message.id}
      style={style}
      className={`w-80 relative py-4 pr-8 pl-4 border rounded-2xl shadow-lg flex items-center mb-2 ${toastColors}`}
    >
      {message?.imageUrl ? (
        <img src={message?.imageUrl} height="64" width="64" />
      ) : (
        <>{icons[message.type || 'info']}</>
      )}

      <div className="flex-1 ml-2 ">
        <strong className="leading-3">{message.title}</strong>
        {message.description && (
          <p className="mt-1 text-xs leading-3 opacity-80">
            {message.description}
          </p>
        )}
      </div>

      <button
        className="absolute right-4 top-5 opacity-60 border-0 bg-transparent"
        style={{ color: 'inherit' }}
        type="button"
        onClick={() => removeToast(message.id)}
      >
        <FiXCircle size={18} />
      </button>
    </animated.div>
  );
};

export default Toast;
