import { Router, withRouter } from 'next/router';
import React from 'react';
import { showMessageStatus } from 'utils/SwalUtils';

type State = {
  hasError: boolean;
};

export type ErrorBoundaryProps = {
  router: Router;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //log the error to an error reporting service
    console.error({ error, errorInfo });
    showMessageStatus({
      titleContent: 'Oops, algo deu errado',
      textContent:
        'Estamos identificando o que pode ter acontecido, que tal voltar para onde estava?',
      imageType: 'error',
      textButton: 'Voltar',
      onIsAccepted: () => {
        this.props.router.back();
      },
      onIsDenied: () => {
        this.props.router.back();
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Oops, something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
