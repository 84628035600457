import { firebaseConfig } from 'config/firebase';
import { useAuth } from 'contexts/Authentication';
import { useToast } from 'contexts/Toast';
import localforage from 'localforage';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { addUserFCMToken } from 'services/apiServices/IProTubeApi/users';
import { firebaseApp } from 'services/firebase';
import { Logger } from 'services/logger';

const logger = new Logger('NotificationsProvider');
interface NotificationsContextData {
  isOpen: boolean;
  openChat: () => void;
  closeChat: () => void;
  selectedChatId: string;
}

const NotificationsContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData,
);

export type FCMNotification = {
  body: string;
  tag: string;
  title: string;
};

export type NotificationsProviderProps = {
  vapidKey: string;
  children: React.ReactNode;
};

const NotificationsProvider = ({
  children,
  vapidKey,
}: NotificationsProviderProps) => {
  const { addToast } = useToast();
  const [notifications, setNotifications] = useState<FCMNotification[]>([]);
  const { state } = useAuth();
  const getFCMToken = useCallback(
    async (messaging): Promise<string | null> => {
      try {
        const savedToken = (await localforage.getItem('fcm_token')) as string;
        if (savedToken !== null) return savedToken;
        const status = await Notification.requestPermission();
        if (status && status === 'granted') {
          const fcm_token = await messaging.getToken({
            vapidKey,
          });
          if (fcm_token) {
            localforage.setItem('fcm_token', fcm_token);
            return fcm_token;
          }
        } else {
          if (status === 'denied') {
            addToast({
              id: 'notifications-warning',
              title: 'Notificações desabilitadas',
              description:
                'Ative as suas notificações para poder ser avisado ao receber novas mensagens.',
              type: 'info',
            });
          }
          logger.debug(`==> notification is not granted. status is ${status}`);
        }
      } catch (error) {
        logger.error(`Error inside getFCMToken`, error);
        return null;
      }
    },
    [vapidKey],
  );

  useEffect(() => {
    async function setupFCM() {
      logger.debug(`==> setupFCM`);
      try {
        const messaging = firebaseApp.messaging();

        const token = await getFCMToken(messaging);

        if (token) {
          logger.debug(`==> fcm token is ${token}`);
          if (
            state.user?.fcmTokens &&
            !state.user?.fcmTokens?.includes(token)
          ) {
            await addUserFCMToken(state.user?._id, {
              fcmToken: token as string,
            }).catch(err => {
              logger.error(`Erro ao adicionar token FCM ao usuário`, err);
            });
          }

          messaging.onMessage(message => {
            logger.debug(
              `==> receiving foreground message ${JSON.stringify(message)}`,
            );

            const title =
              message?.notification?.title || message?.data?.title || '';
            const description =
              message?.notification?.body || message?.data?.body || '';
            const imageUrl =
              message?.notification?.image ||
              message?.data?.image ||
              'https://menobel.com/icons/icon-192.png';

            addToast({
              title,
              description,
              type: 'info',
              imageUrl,
            });
            setNotifications(oldState => [
              ...oldState,
              message.notification as FCMNotification,
            ]);
          });
        } else {
          logger.debug(`==> fcm token is not present`);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (state.user?._id) {
      logger.debug(`Registrando FCM...`);

      setupFCM();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register(
            `/firebase-messaging-sw.js?${new URLSearchParams(
              firebaseConfig,
            ).toString()}`,
          )
          .then(function (registration) {
            logger.debug(
              `==> FCM service worker registered with scope ${registration.scope}`,
            );
            navigator.serviceWorker.addEventListener('message', event => {
              logger.debug(
                `==> receiving event 'message' in service worker ${JSON.stringify(
                  event,
                )}`,
              );
            });
          })
          .catch(function (err) {
            logger.error('Service worker registration failed, error:', err);
          });
      }
    }

    // this is working
  }, [addToast, getFCMToken, state.user?._id, state.user?.fcmTokens]);

  return (
    <NotificationsContext.Provider value={{} as NotificationsContextData}>
      {children}
    </NotificationsContext.Provider>
  );
};

function useNotifications(): NotificationsContextData {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider',
    );
  }

  return context;
}

export { NotificationsProvider, useNotifications };
