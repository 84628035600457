import React from 'react';
import { RiWifiOffLine } from 'react-icons/ri';

const OfflineIndication = () => {
  return null; // TODO Was not working properly

  return (
    <div
      className="absolute fixed top-5 left-5 right-5 lg:top-10 lg:right-10 lg:left-auto bg-white border-t-4 border-red-500 rounded-b text-teal-900 px-4 py-3 shadow-md z-50"
      role="alert"
    >
      <div className="flex bg-white">
        <div>
          <RiWifiOffLine />
          <p className="font-bold">Sem conexão</p>
          <p className="text-sm">
            Reconecte-se a internet para seguir utilizando o Menobel.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfflineIndication;
