import React, { useEffect } from 'react';
import { GOOGLE_ADS_ID } from 'config/constants';

const GoogleAdsScript = () => {
  useEffect(() => {
    if (GOOGLE_ADS_ID) {
      const adsScriptId = 'ads-script';

      if (!document.getElementById(adsScriptId)) {
        setTimeout(function () {
          const elem = document.createElement('script');
          elem.src =
            'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
          elem.async = true;
          elem.id = adsScriptId;
          elem.setAttribute('data-ad-client', GOOGLE_ADS_ID);
          document.head.appendChild(elem);
        }, 2000);
      }
    }
  }, []);
  return <></>;
};

export default GoogleAdsScript;
