import CentralizedSpinnerLoader from 'components/molecules/loaders/CentralizedSpinnerLoader';
import OfflineIndication from 'components/molecules/OfflineIndication';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { Offline } from 'react-detect-offline';

interface PageLoadingState {
  isLoading: boolean;
}
interface PageLoadingContextData {
  state: PageLoadingState;
  setIsLoading: (value: boolean) => void;
}

const PageLoadingContext = createContext<PageLoadingContextData>(
  {} as PageLoadingContextData,
);

const PageLoadingProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<PageLoadingState>({} as PageLoadingState);
  const [loadingComponent, setLoadingComponent] = useState<React.ReactNode>(
    <></>,
  );

  useEffect(() => {
    if (state.isLoading) {
      setLoadingComponent(
        <CentralizedSpinnerLoader size="fullscreen" isFloating={true} />,
      );
    } else {
      setLoadingComponent(<></>);
    }
  }, [state.isLoading]);
  function setIsLoading(value: boolean): void {
    setState(oldState => ({
      ...oldState,
      isLoading: value,
    }));
  }

  return (
    <PageLoadingContext.Provider
      value={
        {
          state,
          setIsLoading,
        } as PageLoadingContextData
      }
    >
      <>
        <Offline>
          <OfflineIndication />
        </Offline>

        {children}
        {loadingComponent}
      </>
    </PageLoadingContext.Provider>
  );
};

function usePageLoading(): PageLoadingContextData {
  const context = useContext(PageLoadingContext);

  if (!context) {
    throw new Error('usePageLoading must be used within a PageLoadingProvider');
  }

  return context;
}

export { PageLoadingProvider, usePageLoading };
