import React from 'react';
import { useTransition } from 'react-spring';
import { ToastMessage } from 'contexts/Toast';
import Toast from './Toast';

interface ToastContainerProps {
  messages: ToastMessage[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const transitions = useTransition(messages, {
    from: {
      right: '-120%',
      opacity: 0,
    },
    enter: {
      right: '0%',
      opacity: 1,
    },
    leave: {
      right: '-120%',
      opacity: 0,
    },
  });
  return (
    <div
      className="fixed right-0 translate-y-4 top-4 overflow-hidden"
      style={{ zIndex: 9999 }}
    >
      {transitions(({ opacity, right }, item) => (
        <Toast key={item.id} message={item} style={{ opacity, right }} />
      ))}
    </div>
  );
};

export default ToastContainer;
