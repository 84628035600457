import SpinnerLoader from 'components/atoms/loaders/SpinnerLoader';
import React from 'react';

export interface ICentralizedSpinnerLoaderProps {
  size?: 'fullscreen' | 'full';
  isFloating?: boolean;
}
const CentralizedSpinnerLoader = ({
  size = 'full',
  isFloating = true,
}: ICentralizedSpinnerLoaderProps) => {
  function getSizeClassNames(): string {
    let className = '';
    if (size === 'full') {
      className += ' h-full w-full';
    }

    if (size === 'fullscreen') {
      className += 'h-screen w-screen';
    }

    if (isFloating) {
      className += ' fixed block top-0 left-0 z-50';
    }
    return className;
  }
  function getSpinnerPreferences(): string {
    let className = '';

    if (isFloating) {
      className += ' bg-opacity-0 z-50';
    }

    return className;
  }
  return (
    <>
      {isFloating && (
        <div
          className={`fixed block top-0 left-0 bg-white opacity-50 z-45 ${getSizeClassNames()}`}
        ></div>
      )}
      <div
        className={`flex flex-col items-center justify-center ${getSizeClassNames()}`}
      >
        <SpinnerLoader extendClassName={getSpinnerPreferences()} />
      </div>
    </>
  );
};

export default CentralizedSpinnerLoader;
