/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from 'contexts/Authentication';
import React, { createContext, useContext, useCallback } from 'react';

interface FeatureFlagsContextData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFeatureFlag: <ValueType = any>(key: string) => ValueType | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFeatureFlag: <ValueType = any>(key: string, value: ValueType) => void;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextData>(
  {} as FeatureFlagsContextData,
);

const FeatureFlagsProvider: React.FC = ({ children }) => {
  const { state } = useAuth();

  const getKey = useCallback((key: string) => `@_${state.user?._id}_${key}`, [
    state.user?._id,
  ]);

  const getFeatureFlag = useCallback(
    (key: string): any | null => {
      if (typeof window !== 'undefined') {
        const value = window.localStorage.getItem(getKey(key));

        if (value !== null) {
          return JSON.parse(value);
        }
      }
      return null;
    },
    [getKey],
  );

  const setFeatureFlag = useCallback(
    (key: string, value: any) => {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(getKey(key), JSON.stringify(value));
      }
    },
    [getKey],
  );

  return (
    <FeatureFlagsContext.Provider
      value={
        {
          getFeatureFlag,
          setFeatureFlag,
        } as FeatureFlagsContextData
      }
    >
      <>{children}</>
    </FeatureFlagsContext.Provider>
  );
};

function useFeatureFlags(): FeatureFlagsContextData {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider',
    );
  }

  return context;
}

export { FeatureFlagsProvider, useFeatureFlags };
