import React from 'react';
import Image from 'next/image';
import { AppProps } from 'next/app';
import { CookiesProvider } from 'react-cookie';
import { TourProvider } from '@reactour/tour';
import { appWithTranslation } from 'next-i18next';
import * as nextI18NextConfig from '../../next-i18next.config';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import GoogleAdsScript from 'services/adsense/google/GoogleAdsScript';
import SEO from 'components/molecules/others/SEO';
import CookiesConsent from 'components/molecules/popups/CookiesConsent';
import 'tailwindcss/tailwind.css';
import '../styles/global.css';
import 'react-responsive-modal/styles.css';
import 'react-medium-image-zoom/dist/styles.css';
import { PageLoadingProvider } from 'contexts/PageLoading';
import { AuthProvider } from 'contexts/Authentication';
import { SocketProvider } from 'contexts/Socket';
import { FloatingChatProvider } from 'contexts/FloatingChat';
import { NotificationsProvider } from 'contexts/Notifications';
import { ToastProvider } from 'contexts/Toast';
import { FIREBASE_FCM_VAPID } from 'config/constants';
import ErrorBoundary from 'components/molecules/ErrorBoundary';
import { FeatureFlagsProvider } from 'contexts/FeatureFlags';
import { ContentProps } from '@reactour/tour/dist/components/Content';
import IntercomScript from 'services/intercom/IntercomScript';

const Content: React.ComponentType<ContentProps> = ({ content }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-center mb-4">
        <Image
          className="p-4"
          src="/branding/logo-colored-icon.svg"
          width={20}
          height={20}
        />
      </div>
      <span className="font-primary">{content}</span>
    </div>
  );
};
function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <CookiesProvider>
        <TourProvider
          components={{
            Content,
          }}
          steps={[]}
        >
          <PageLoadingProvider>
            <AuthProvider>
              <FeatureFlagsProvider>
                <SocketProvider>
                  <FloatingChatProvider>
                    <ToastProvider>
                      <NotificationsProvider vapidKey={FIREBASE_FCM_VAPID}>
                        <SEO
                          title={
                            'Menobel - Dicas, opiniões, conselhos, inspirações e histórias incríveis'
                          }
                        />
                        <IntercomScript />
                        <Component {...pageProps} />
                        <GoogleAdsScript />
                        <CookiesConsent />
                      </NotificationsProvider>
                    </ToastProvider>
                  </FloatingChatProvider>
                </SocketProvider>
              </FeatureFlagsProvider>
            </AuthProvider>
          </PageLoadingProvider>
        </TourProvider>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'footer'])),
  },
});

export default appWithTranslation(MyApp);
