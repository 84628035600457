import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';

const CookiesConsent = () => {
  return (
    <ReactCookieConsent
      enableDeclineButton={false}
      buttonText="Aceitar"
      buttonStyle={{
        background: 'rgba(5, 150, 105, 0.7)',
        color: 'white',
      }}
      declineButtonStyle={{
        background: 'grey',
      }}
      declineButtonText="Negar"
    >
      <p>Esse website usa cookies para melhorar a experiência do usuário.</p>
      <small>Funcionalidades importantes dependem dos cookies.</small>
    </ReactCookieConsent>
  );
};

export default CookiesConsent;
